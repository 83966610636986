import React from "react";
import DataTable from "react-data-table-component";

const DatatableToShow = ({ columns, data, title,subHeaderComponent }) => {

  return <DataTable 
  columns={columns} 
  data={data} 
  title={title} 
  pagination 
  subHeader
  highlightOnHover
  subHeaderComponent={subHeaderComponent}
  fixedHeader={true}
  responsive
//   selectableRows
  
  
  />;
};

export default DatatableToShow;
