import React from 'react'
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const FileUploadFF = ({name, onChange, required = true}) => {

  return (
    <Button
    component="label"
    role={undefined}
    variant="contained"
    tabIndex={-1}
    startIcon={<CloudUploadIcon />}
    style={{width:'100%'}}
  >
    Upload file
    <VisuallyHiddenInput onChange={onChange} name={name} type="file" required={required} />
  </Button>
  )
}

export default FileUploadFF
