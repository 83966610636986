import React from 'react'
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
const ItemCard = ({vendor}) => {
  console.log(vendor)
  return (
    <div className='item_box'>
      <div className="item_box__item item_box__item_1">
        <img src="https://static.pbcdn.in/cdn/images/insurer-logo/canara-hsbc-life-insurance.jpg"  />
        <p className='item_box__item_p_signature'>{vendor.v_b_name}</p>
      </div>
   
      <div className="item_box__item item_box__item_2">
        <h4 className="item_box__item_h4">Past 5 Yrs Returns</h4>
        <h2 className="item_box__item_h2 item_box__item_green">29.24%</h2>
        <p className="item_box__item_p item_box__item_bg">Mid Cap 150 Momentum 50 Fund</p>
      </div>
      <div className="item_box__item item_box__item_3">
      <h4 className="item_box__item_h4">Lum sum Payout</h4>
        <h2 className="item_box__item_h2">₹6.75 Cr</h2>
        <p className="item_box__item_p">If you had invested 20 Yrs ago</p>
      </div>
 
      <div className="item_box__item item_box__item_4">
        <Button type="button"
            
                variant="contained"
                className='item_box__know_more_btn'
                ><NavLink to={"/client-registration"} className="item_box__item_know_more">Know More</NavLink></Button>
      </div>
    </div>
    // <div className='item_box'>
    //   <div className="item_box__item item_box__item_1">
    //     <img src="https://static.pbcdn.in/cdn/images/insurer-logo/canara-hsbc-life-insurance.jpg"  />
    //     <p className='item_box__item_p_signature'>Signature</p>
    //   </div>
   
    //   <div className="item_box__item item_box__item_2">
    //     <h4 className="item_box__item_h4">Past 5 Yrs Returns</h4>
    //     <h2 className="item_box__item_h2 item_box__item_green">29.24%</h2>
    //     <p className="item_box__item_p item_box__item_bg">Mid Cap 150 Momentum 50 Fund</p>
    //   </div>
    //   <div className="item_box__item item_box__item_3">
    //   <h4 className="item_box__item_h4">Lum sum Payout</h4>
    //     <h2 className="item_box__item_h2">₹6.75 Cr</h2>
    //     <p className="item_box__item_p">If you had invested 20 Yrs ago</p>
    //   </div>
 
    //   <div className="item_box__item item_box__item_4">
    //     <Button type="button"
            
    //             variant="contained"
    //             className='item_box__know_more_btn'
    //             ><NavLink to={"/client-registration"} className="item_box__item_know_more">Know More</NavLink></Button>
    //   </div>
    // </div>
  )
}

export default ItemCard
