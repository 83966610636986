import axios from "axios";

export const baseURL = "https://claps24api.comuniqate.com/api";

export const registerUser = async (payload) => {
  try {
    const response = await axios.post(`${baseURL}/regi_master`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Registration failed");
    }
  } catch (error) {
    console.error("Error registering user:", error);
    return false;
  }
};
export const loginUser = async (payload) => {
  try {
    const response = await axios.post(`${baseURL}/login`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Registration failed");
    }
  } catch (error) {
    console.error("Error login user:", error);
    return false;
  }
};



// client api calls
// sending files in client register
export const clientRegistration = async (payload) => {
  try {
    const response = await axios.post(
      `${baseURL}/client-registration`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 201) {
      return response;
    } else {
      throw new Error("Client Registration failed");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};

export const fetchClientsList = async () => {
  try {
    const response = await axios.post(`${baseURL}/client-name-list`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Some Error.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};

export const clientFindOne = async (payload) => {
  try {
    const response = await axios.post(`${baseURL}/client-find-one`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Some Error.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};

export const clientUpdation = async (payload) => {
  try {
    const response = await axios.post(
      `${baseURL}/client-update`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Client Updating failed");
    }
  } catch (error) {
    console.error("Error updating user:", error);
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};

export const clientDeleteOne = async (payload) => {
  try {
    const response = await axios.post(`${baseURL}/client-delete`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Some Error.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};
// vendor api calls
export const vendorRegistration = async (payload) => {
  try {
    const response = await axios.post(
      `${baseURL}/vendor-registration`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 201) {
      return response;
    } else {
      throw new Error("Vendor Registration failed");
    }
  } catch (error) {
    console.error("Error registering user:", error);
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};

export const fetchVendorList = async (payload) => {
  try {
    const response = await axios.post(`${baseURL}/vendor-name-list`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Some Error.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};

export const updateVendorStatus = async (payload) => {
  try {
    const response = await axios.post(
      `${baseURL}/vendor-change-status`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Some Error.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};
export const vendorFindOne = async (payload) => {
  try {
    const response = await axios.post(`${baseURL}/vendor-find-one`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Some Error.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};
export const vendorUpdation = async (payload) => {
  try {
    const response = await axios.post(
      `${baseURL}/vendor-update`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Vendor Updating failed");
    }
  } catch (error) {
    console.error("Error updating user:", error);
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};
export const vendorDeleteOne = async (payload) => {
  try {
    const response = await axios.post(`${baseURL}/vendor-delete`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Some Error.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      // Backend provided an error message
      return error.response.data.error;
    } else {
      // Fallback to generic error message
      return "An error occurred";
    }
  }
};