import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Container, Grid } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import InputFF from "../component/formFiels/InputFF";
import DatePickerFF from "../component/formFiels/DatePickerFF";
import FileUploadFF from "../component/formFiels/FileUploadFF";
import SelectDropDownFF from "../component/formFiels/SelectDropDownFF";
import CheckBoxFF from "../component/formFiels/CheckBoxFF";
import { clientRegistration, fetchVendorList,clientUpdation } from "../Utils/API_Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

const ClientRegistration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [updateFormData, setUpdateFormData] = useState([]);
  const [selectProviderItems, setSelectProviderItems] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  // const selectProviderItems = {
  //   bajaj: "Bajaj Finance",
  //   hdfc: "HDFC Finance",
  //   lic: "LIC of India",
  // };

  useEffect(() => {
    if (location?.state?.client?._id) {
      let dateObject = new Date(location?.state?.client?.c_td);
      // Extract the year, month, and day from the date object
      var year = dateObject.getFullYear();
      var month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
      var day = ("0" + dateObject.getDate()).slice(-2);

      // Construct the yyyy-mm-dd format
      var yyyy_mm_dd = year + "-" + month + "-" + day;
      setUpdateFormData({
        ...location?.state?.client,
        c_td: yyyy_mm_dd,
        c_vendor_provider: location?.state?.client?.c_vendor_provider?._id,
      });
    }
  }, []);

  // fetch vendor providers
  useEffect(() => {
    fetchVendors();
  }, []);

  const fetchVendors = async () => {
    const response = await fetchVendorList();
    if (response.status == 200) {
      const vendorItems = response?.data?.vendorList.reduce((item, index) => {
        item[index._id] = index.v_full_name;
        return item;
      }, {});

      console.log("Vendor:", vendorItems);
      setSelectProviderItems(vendorItems);
    }
  };

  const updateFormStateOnUpdate = (e) => {
    setUpdateFormData({ ...updateFormData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUpdateFormData({ ...updateFormData, c_doc_file: event.target.files[0] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const registrationResult = await clientRegistration(updateFormData);
      if (registrationResult.status === 201) {
        // Registration successful

        const SuccessMsg = registrationResult?.data?.message
          ? registrationResult?.data?.message
          : "Client Registration Successfull.";
        toast.success(SuccessMsg);
      } else {
        const ErrorMsg = registrationResult;
        toast.error(ErrorMsg);
      }
    } catch (error) {
      toast.error(error);
      console.error("Error registering user:", error);
    }
  };
  const handleUpdate = async (event) => {
    //console.log('Handle Update Called...')
    event.preventDefault();
    console.log("Update par bhej de bhai ", updateFormData);
 
    try {
      const updateFormDataForAPI = {
        ...updateFormData,
        id: location?.state?.client?._id,
      };
      //console.log(updateFormDataForAPI);
      const updatationResult = await clientUpdation(updateFormDataForAPI);
      if (updatationResult.status === 200) {
        // Registration successful

        const SuccessMsg = updatationResult?.data?.message
          ? updatationResult?.data?.message
          : "Client Updated Successfull.";
        toast.success(SuccessMsg, {
          onClose: () => {
            navigate("/admin/clients-lists");
          },
        });
      } else {
        const ErrorMsg = updatationResult;
        toast.error(ErrorMsg);
      }
    } catch (error) {
      toast.error(error);
      console.error("Error registering user:", error);
    }
  };

  console.log('Client h' , location?.state?.client)
  return (
    <div className="main_box">
      <Container className="client__container">
        <Grid container spacing={2} className="client__grid_container">
          <Grid item xs={12} sm={4}>
            <img
              src={
                location?.state?.client
                  ? "../images/client.png"
                  : "./images/client.png"
              }
              className="client__img"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <form
              id="client_registration_form"
              onSubmit={location?.state?.client ? handleUpdate : handleSubmit}
            >
              <TableContainer
                style={{
                  background: "#f9f9f9",
                  borderRadius: "10px",
                  borderTop: "2px solid #1976D2",
                }}
              >
                <Table aria-label="simple table" style={{ border: "0px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        {location?.state?.client
                          ? "Edit Customer Application"
                          : "Customer Application"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Full Name
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="Full Name"
                          variant="outlined"
                          type="text"
                          name="c_f_name"
                          required={true}
                          value={
                            updateFormData?.c_f_name ?? updateFormData?.c_f_name
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Email Address
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="Email"
                          variant="outlined"
                          type="email"
                          name="c_email"
                          required={true}
                          value={
                            updateFormData?.c_email ?? updateFormData?.c_email
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Mobile
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="What'sApp Number"
                          variant="outlined"
                          type="tel"
                          name="c_tel"
                          required={true}
                          value={updateFormData?.c_tel ?? updateFormData?.c_tel}
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Date of Transation
                      </TableCell>
                      <TableCell align="right">
                        <DatePickerFF
                          name="c_td"
                          required={true}
                          format="DD/MM/YYYY"
                          value={updateFormData?.c_td ?? updateFormData?.c_td}
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                   
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Attach Doc
                        </TableCell>
                        <TableCell align="right">
                          <FileUploadFF
                            name="c_doc_file"
                            value={
                              updateFormData?.c_doc_file ??
                              updateFormData?.c_doc_file
                            }
                            onChange={handleFileChange}
                            required={ location?.state?.client == undefined ? true : false}
                          />
                           { 'Attached Doc: ' + updateFormData?.c_doc_file?.name}
                        </TableCell>
                       
                      </TableRow>
                  
                     
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Provider/Vendor
                      </TableCell>
                      <TableCell align="right">
                        <SelectDropDownFF
                          label="Select Provider"
                          menuItem={selectProviderItems}
                          name="c_vendor_provider"
                          selectedValue={
                            updateFormData?.c_vendor_provider ??
                            updateFormData?.c_vendor_provider?._id
                          }
                          onChange={updateFormStateOnUpdate}
                          required={true}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" colSpan={2}>
                        <CheckBoxFF
                          name="c_terms_conditions"
                          required
                          label="I hereby accept all the terms & conditions & Services as informed"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{ width: "100%" }}
                >
                  {location?.state?.client ? "Update Now" : "Submit Now"}
                </Button>
              </TableContainer>
            </form>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default ClientRegistration;
