import React, { useEffect, useState } from "react";
import DatatableToShow from "../../component/DatatableToShow";
import {
  fetchVendorList,
  updateVendorStatus,
  vendorFindOne,
  vendorDeleteOne,
} from "../../Utils/API_Axios";
import { Container, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const VendorsList = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const naviagate = useNavigate();

  // fetch from api
  useEffect(() => {
    fetchVendorsList();
  }, []);

  // filter data when search start
  useEffect(() => {
    filterDataToShow();
  }, [searchTerm]);

  // fetch data
  const fetchVendorsList = async () => {
    const response = await fetchVendorList({ showAll: "showAll" }); // {showAll:"showAll"} means fetch all with status
    setData(response.data.vendorList);
    setFilterData(response.data.vendorList);
  };

  const handleStatusChange = async (id, status) => {
    const updatedStatus = status === "active" ? "deActive" : "active";
    try {
      const updateVStatus = await updateVendorStatus({
        id,
        status: updatedStatus,
      });
      if (updateVStatus.status === 200) {
        const SuccessMsg = updateVStatus?.data?.message
          ? updateVStatus?.data?.message
          : "Vendor Status Updated.";
        toast.success(SuccessMsg);
        fetchVendorsList();
      } else {
        const ErrorMsg = updateVStatus;
        toast.error(ErrorMsg);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleEdit = async (id) => {
    try {
      const vendorOne = await vendorFindOne({ id });
      if (vendorOne.status === 200) {
        naviagate("/admin/vendor-registration-edit", {
          state: { vendor: vendorOne?.data?.vendor },
        });
        // console.log(vendorOne?.data?.vendor);
      } else {
        const ErrorMsg = vendorOne;
        toast.error(ErrorMsg);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleDelete = async (id) => {
    // change status from here
    const confirmed = window.confirm(
      "Are you sure you want to delete the vendor?, Deleting Once Never Came Back."
    );
    if (confirmed) {
      try {
        const vendorDelete = await vendorDeleteOne({ id });
        if (vendorDelete.status === 200) {
          const SuccessMsg = vendorDelete?.data?.message
            ? vendorDelete?.data?.message
            : "Vendor Deleted Succefully.";
          toast.success(SuccessMsg);
          fetchVendorsList();
          // console.log(vendorOne?.data?.vendor);
        } else {
          const ErrorMsg = vendorDelete;
          toast.error(ErrorMsg);
        }
      } catch (error) {
        toast.error(error);
      }
    }
    //return alert(id);
  };

  const filterDataToShow = () => {
    const filterNewRecord = data.filter((row) => {
      return (
        row.v_full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.v_b_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.v_commission_charge
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        row.v_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.v_tel.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.v_full_address.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilterData(filterNewRecord);
  };

  const tableColumns = [
    {
      name: "Full Name",
      selector: (row) => row.v_full_name,
      sortable: true,
    },
    {
      name: "Business Name",
      selector: (row) => row.v_b_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.v_email,
      sortable: true,
    },
    {
      name: "Mobile/Whatsapp",
      selector: (row) => row.v_tel,
      // sortable: true
    },
    {
      name: "Address",
      selector: (row) => row.v_full_address,
      // sortable: true
    },
    {
      name: "Commission Charges",
      selector: (row) => row.v_commission_charge,
      sortable: true,
    },
    {
      name: "Business Category",
      selector: (row) => row.v_b_category,
      sortable: true,
    },
    {
      name: "GSTIN Number",
      selector: (row) => row?.v_gstin,
      // sortable: true
    },
    {
      name: "Web / App Link",
      selector: (row) => row.v_link,
      // sortable: true
    },
    {
      name: "Status",
      // selector: row => row.v_status,
      sortable: true,
      style: { width: "400px" },

      cell: (row) => {
        return (
          <Button
            variant="contained"
            color={row.v_status === "active" ? "success" : "error"}
            onClick={() => handleStatusChange(row._id, row.v_status)}
          >
            {row.v_status === "active" ? "Active" : "Deactive"}
          </Button>
        );
      },
    },
    {
      name: "Edit",
      cell: (row) => (
        <Button
          variant="contained"
          color="warning"
          onClick={() => handleEdit(row._id)}
        >
          Edit
        </Button>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDelete(row._id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Container
      maxWidth="xl"
      style={{
        margin: "20px auto",
        border: "1px solid rgba(0,0,0,0.1)",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
      }}
    >
      <DatatableToShow
        columns={tableColumns}
        data={filterData}
        title="Vendors Lists"
        subHeaderComponent={
          <>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ padding: "5px", marginRight: "10px" }}
                />
                {/* <Button>Add New Record</Button> */}
              </div>
            </div>
          </>
        }
      />
      <ToastContainer />
    </Container>
  );
};

export default VendorsList;
