import React from "react";



const DatePickerFF = ({ name, value, format, onChange }) => {
  const date = new Date(); // Assuming your date object is named 'date'
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return (

      <input type='date'
        name={name}
        value={value ? value : formattedDate}
        onChange={onChange}
        fullWidth
        className="full-width-datepicker"
        format={format}
        
      />

  );
};

export default DatePickerFF;
