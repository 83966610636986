import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Dashboard = () => {
  useEffect(() => {
    // replace with login details
    localStorage.setItem("login", true);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        // flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
      }}
    >
      <NavLink
        to={"/admin/clients-lists"}
        style={{
          // height:'50px',
          backgroundColor: "#f8f8f8",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px",
          columnGap: "30px",
          rowGap:'20px',
          width:'200px'
        }}
      >
        Client Lists
      </NavLink>
      <NavLink
        to={"/admin/vendors-lists"}
        style={{
          // height:'50px',
          backgroundColor: "#f8f8f8",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px",
          gap: "30px",
          width:'200px'
        }}
      >
        Vendor Lists
      </NavLink>
    </div>
  );
};

export default Dashboard;
