import React, { useEffect, useState } from "react";
import DatatableToShow from "../../component/DatatableToShow";
import {
  fetchClientsList,
  clientFindOne,
  clientDeleteOne,
  baseURL,
} from "../../Utils/API_Axios";
import { Container, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";

const ClientsList = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const naviagate = useNavigate();

  // fetch from api
  useEffect(() => {
    fetchClientLists();
  }, []);

  // filter data when search start
  useEffect(() => {
    filterDataToShow();
  }, [searchTerm]);

  // fetch data
  const fetchClientLists = async () => {
    const response = await fetchClientsList();
    console.log(response.data.clientList);
    setData(response.data.clientList);
    setFilterData(response.data.clientList);
  };

  const handleEdit = async (id) => {
    try {
      const clientOne = await clientFindOne({ id });
      if (clientOne.status === 200) {
        naviagate("/admin/client-registration-edit", {
          state: { client: clientOne?.data?.client },
        });
        // console.log(clientOne?.data?.client);
      } else {
        const ErrorMsg = clientOne;
        toast.error(ErrorMsg);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleDelete = async (id) => {
    // change status from here
    const confirmed = window.confirm(
      "Are you sure you want to delete the client?, Deleting Once Never Came Back."
    );
    if (confirmed) {
      try {
        const clientDelete = await clientDeleteOne({ id });
        if (clientDelete.status === 200) {
          const SuccessMsg = clientDelete?.data?.message
            ? clientDelete?.data?.message
            : "Client Deleted Succefully.";
          toast.success(SuccessMsg);
          fetchClientLists();
          // console.log(vendorOne?.data?.vendor);
        } else {
          const ErrorMsg = clientDelete;
          toast.error(ErrorMsg);
        }
      } catch (error) {
        toast.error(error);
      }
    }
    //return alert(id);
  };

  const filterDataToShow = () => {
    const filterNewRecord = data.filter((row) => {
      return (
        row.c_f_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.c_vendor_provider?.v_full_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        row.c_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.c_tel.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilterData(filterNewRecord);
  };

  const tableColumns = [
    {
      name: "Full Name",
      selector: (row) => row.c_f_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.c_email,
      sortable: true,
    },
    {
      name: "Mobile/Whatsapp",
      selector: (row) => row.c_tel,
      // sortable: true
    },
    {
      name: "Date of Transation",
      selector: (row) => row.c_td,
      // sortable: true
    },
    {
      name: "Documents",
      sortable: true,
      cell: (row) => {
        return (
          <>
            {/* <img
              src={`${baseURL}${row.c_doc_file}`}
              height={"30px"}
              width={"30px"}
            /> */}

            <a href={`${baseURL}${row.c_doc_file}`}>View</a>
          </>
        );
      },
    },
    {
      name: "Vendor Provider",
      selector: (row) => row.c_vendor_provider?.v_full_name,
      sortable: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <Button
          variant="contained"
          color="warning"
          onClick={() => handleEdit(row._id)}
        >
          Edit
        </Button>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDelete(row._id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Container
      maxWidth="xl"
      style={{
        margin: "20px auto",
        border: "1px solid rgba(0,0,0,0.1)",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
      }}
    >
      <DatatableToShow
        columns={tableColumns}
        data={filterData}
        title="Clients Lists"
        subHeaderComponent={
          <>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ padding: "5px", marginRight: "10px" }}
                />
                {/* <Button>Add New Record</Button> */}
              </div>
            </div>
          </>
        }
      />
      <ToastContainer />
    </Container>
  );
};

export default ClientsList;
