import React from 'react'
import {Select, FormControl,InputLabel,MenuItem} from "@mui/material";





const SelectDropDownFF = ({label,name,menuItem,onChange,selectedValue = ''}) => {
 
    const menuItemArr = Object.entries(menuItem)
  return (
    <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">
      {label}
    </InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      label="Select Provider"
      name={name}
      // defaultValue=""
      value={selectedValue}
      onChange={onChange}
    >
       {menuItemArr.map(([key,_item])=><MenuItem  key={key} value={key}>{_item}</MenuItem>)}
       
    </Select>
  </FormControl>
  )
}

export default SelectDropDownFF
