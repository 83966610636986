import React from "react";
import { TextField } from "@mui/material";

const InputFF = ({
  label,
  variant,
  type,
  value = '',
  name,
  onChange,
  required = true,
  disabled = false,
  placeholder = ''
}) => {
  
  const handleChange = onChange && typeof onChange === 'function' ? onChange : () => {};
  return (
    <TextField
      fullWidth
      label={label}
      onChange={handleChange}
      name={name}
      value={value}
      variant={variant}
      type={type}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default InputFF;
