import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckBoxFF = ({ label, required, name }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          defaultChecked
          required={required}
          name={name}
          value={'on'}
        />
      }
      label={label}
    />
  );
};

export default CheckBoxFF;
