import Navbar from "./component/Navbar";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import FooterBar from "./component/FooterBar";
import ClientRegistration from "./pages/ClientRegistration";
import VendorRegistration from "./pages/VendorRegistration";
import Dashboard from "./pages/admin/Dashboard";
import AdminNavbar from "./pages/admin/AdminNavbar";
import AdminFooterBar from "./pages/admin/AdminFooterBar";
import VendorsList from "./pages/admin/VendorsList";
import ClientsList from "./pages/admin/ClientsList";

const Contact = () => <h2>Contact</h2>;

function App() {
  const isLogin = localStorage.getItem('login');
  console.log('isLogin', isLogin);
  return (
    <>
        

      <Router>
        {
          isLogin ? <AdminNavbar/>  : <Navbar/>
        }
   
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/client-registration" element={<ClientRegistration/>} />
          <Route path="/vendor-registration" element={<VendorRegistration/>} />
          <Route path="/list-items" element={<Contact/>} />

          {/* admin panel routes */}
          <Route path="/admin/dashboard" element={<Dashboard/>} />


          <Route path="/admin/vendors-lists" element={<VendorsList/>} />
          <Route path="/admin/clients-lists" element={<ClientsList/>} />


          <Route path="/admin/vendor-registration-edit" element={<VendorRegistration/>} />
          <Route path="/admin/client-registration-edit" element={<ClientRegistration/>} />
        </Routes>
        {
          isLogin ? <AdminFooterBar/> :  <FooterBar/>
        }
        
        {/* <Routes>
         
        </Routes> */}
      </Router>
    </>
  );
}

export default App;
