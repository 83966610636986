import React from 'react'

const AdminFooterBar = () => {
  return (
    <div className='footer__bar'>
      <p>All Rights Reserved by Admin Footer Me.</p>
    </div>
  )
}

export default AdminFooterBar
