import React from "react";

const FooterBar = () => {
  return (
    <>
      <div className="footer__bar">
        <p>All Rights Reserved by Me.</p>
      </div>
      <a href="https://wa.me/message/EMQJ5N7TYCQQL1" target="_blank">
        <img
          src="./images/wpchat.png"
          alt="WhatsApp Chat"
          style={{
            height: "50px",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndexindex: 9999,
            boxShadow:'0px 0px 10px rgba(0,0,0,0.4)'
          }}
        />
       
      </a>
    </>
  );
};

export default FooterBar;
