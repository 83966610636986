import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Container, Grid } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import InputFF from "../component/formFiels/InputFF";
import SelectDropDownFF from "../component/formFiels/SelectDropDownFF";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ItemCard from "../component/ItemCard";
import { fetchVendorList } from "../Utils/API_Axios";

ChartJS.register(ArcElement, Tooltip, Legend);

const Home = () => {
  const [calData, setCalData] = useState({
    processCharge: 0,
    spendAmount: 0,
    returnPeriod: 0,
  });
  const [chartData, setChartData] = useState({
    spendAmount: 12000,
    freeReturn: 18000,
  });
  const [vendorData, setVendorData] = useState([]);
  const [freeReturnAmount, setFreeReturnAmount] = useState("");

  const onChangeHandler = (e) => {
    console.log(e.target.value);
    setCalData({ ...calData, [e.target.name]: e.target.value });
  };

  const handleCalculate = () => {

    const spendAmount = parseFloat(calData.spendAmount); 
    const freeReturnPeriod = parseFloat(calData.returnPeriod); 

    // Shopping Amount ÷ Interest Rate × Year = Free Returns

    const freeReturns = (spendAmount / 7) * freeReturnPeriod





    setChartData({
      spendAmount: spendAmount,
      freeReturn: freeReturns,
    });
    setFreeReturnAmount(formatMoney(freeReturns));
  };
  const selectYears = {
    1: "1 Year",
    2: "2 Years",
    3: "3 Years",
    4: "4 Years",
    5: "5 Years",
    10: "10 Years",
    15: "15 Years",
    20: "20 Years",
  };

  const data = {
    labels: ["Shopping amount / Spend Amount", "Total Returns"],
    datasets: [
      {
        label: "Rupees",
        data: [chartData.spendAmount, chartData.freeReturn],
        backgroundColor: ["rgba(255,0,0,0.3)", "rgba(0,255,0,0.3)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  function formatMoney(number) {
    // Create a number formatter instance for INR
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    });

    // Format the number as currency
    return formatter.format(number);
  }

  const fetchVendorsList = async () => {
    const response = await fetchVendorList(); 
    setVendorData(response.data.vendorList);
  };

  useEffect(() => {
    fetchVendorsList();
  }, []);

  return (
    <div className="main_box">
      <Container className="client__container">
        <Grid container spacing={2} className="client__grid_container">
          <Grid item xs={12} sm={8}>
            <TableContainer
              style={{
                background: "#f9f9f9",
                borderRadius: "10px",
                borderTop: "2px solid #1976D2",
              }}
            >
              <Table aria-label="simple table" style={{ border: "0px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <h2>CLAPS24x7 CALCULATOR</h2>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Investment amount / Processing Charges
                    </TableCell>
                    <TableCell align="right">
                      <InputFF
                        // label="Investment amount / Processing Charges"
                        variant="outlined"
                        type="text"
                         disabled={true}
                        //value={0}
                        name="processCharge"
                       // onChange={onChangeHandler}
                        placeholder="Rs: 00 (No Processing Charges)"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Shopping amount / Spent Amount
                    </TableCell>
                    <TableCell align="right">
                      <InputFF
                        label="Shopping amount / Spend Amount"
                        variant="outlined"
                        type="number"
                        name="spendAmount"
                        value={calData.spendAmount}
                        onChange={onChangeHandler}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Free Returns Period
                    </TableCell>
                    <TableCell align="right">
                      <SelectDropDownFF
                        label="Years"
                        menuItem={selectYears}
                        onChange={onChangeHandler}
                        selectedValue={calData.returnPeriod}
                        name="returnPeriod"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" colSpan={2}>
                      <Button
                        type="button"
                        variant="contained"
                        size="large"
                        style={{ width: "50%" }}
                        onClick={handleCalculate}
                      >
                        Calculate
                      </Button>
                      <p
                        style={{
                          fontSize: "24px",
                          letterSpacing: "2px",
                          wordSpacing: "5px",
                        }}
                      >
                        {" "}
                        Expected Free Returns Upto:{" "}
                        <span style={{ fontWeight: "800", fontSize: "30px" }}>
                          {freeReturnAmount
                            ? freeReturnAmount
                            : "Rs 14,4000.25"}
                        </span>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Button
                type="button"
                variant="contained"
                size="large"
                style={{ width: "100%", fontSize: "20px" }}
                color="success"
              >
                Register My Amount For Free Returns
              </Button>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Doughnut data={data} />
          </Grid>
        </Grid>
        <div className="item_container">
          <h3>Top Investment Plans 2024</h3>
          {
            vendorData.map((vendor) => {
              return <ItemCard key={vendor._id} vendor={vendor} />
            })
          }
          {/* <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard /> */}
        </div>
      </Container>
    </div>
  );
};

export default Home;
