import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Container, Grid } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import InputFF from "../component/formFiels/InputFF";
import SelectDropDownFF from "../component/formFiels/SelectDropDownFF";
import CheckBoxFF from "../component/formFiels/CheckBoxFF";
import { vendorRegistration, vendorUpdation } from "../Utils/API_Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

const VendorRegistration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [updateFormData, setUpdateFormData] = useState([]);

  // console.log(location?.state?.vendor?._id);
  //console.log('data ' , location?.state?.vendor?.v_b_category , updateFormData?.v_full_name);

  useEffect(() => {
    if (location?.state?.vendor?._id) {
      console.log("Edit krna aaya hu");
      setUpdateFormData(location?.state?.vendor);
    }
  }, []);

  const selectStatus = {
    active: "Active",
    deActive: "De Active",
  };

  const updateFormStateOnUpdate = (e) => {
    setUpdateFormData({ ...updateFormData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log('Handle Submit Called...')

    const formData = new FormData(e.target);
    console.log(updateFormData);
    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ': ' + pair[1]);
    // }
    try {
      const registrationResult = await vendorRegistration(formData);
      if (registrationResult.status === 201) {
        // Registration successful

        const SuccessMsg = registrationResult?.data?.message
          ? registrationResult?.data?.message
          : "Vendor Registration Successfull.";
        toast.success(SuccessMsg);
      } else {
        const ErrorMsg = registrationResult;
        toast.error(ErrorMsg);
      }
    } catch (error) {
      toast.error(error);
      console.error("Error registering user:", error);
    }
  };

  // update now only for admin
  const handleUpdate = async (event) => {
    //console.log('Handle Update Called...')
    event.preventDefault();

    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ': ' + pair[1]);
    // }
    try {
      const updateFormDataForAPI = {
        ...updateFormData,
        id: location?.state?.vendor?._id,
      };
      //console.log(updateFormDataForAPI);
      const updatationResult = await vendorUpdation(updateFormDataForAPI);
      if (updatationResult.status === 200) {
        // Registration successful

        const SuccessMsg = updatationResult?.data?.message
          ? updatationResult?.data?.message
          : "Vendor Updated Successfull.";
        toast.success(SuccessMsg, {
          onClose: () => {
            navigate("/admin/vendors-lists");
          },
        });
      } else {
        const ErrorMsg = updatationResult;
        toast.error(ErrorMsg);
      }
    } catch (error) {
      toast.error(error);
      console.error("Error registering user:", error);
    }
  };

  return (
    <div className="main_box">
      <Container className="vendor__container">
        <Grid container spacing={2} className="vendor__grid_container">
          {/* <Grid item xs={12} sm={6}>
            <img src='./images/vendor.webp' className="vendor__img" />
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <form
              onSubmit={location?.state?.vendor ? handleUpdate : handleSubmit}
            >
              <TableContainer
                style={{
                  background: "#f9f9f9",
                  borderRadius: "10px",
                  borderTop: "2px solid #1976D2",
                }}
              >
                <Table aria-label="simple table" style={{ border: "0px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "30%" }}>
                        {location?.state?.vendor
                          ? "Edit Vendor Registration"
                          : "Vendor Registration"}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Seller Full Name
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="Full Name"
                          variant="outlined"
                          type="text"
                          name="v_full_name"
                          required={true}
                          value={
                            updateFormData?.v_full_name ??
                            updateFormData?.v_full_name
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Registered Business Name
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="Registered Business Name"
                          variant="outlined"
                          type="text"
                          name="v_b_name"
                          required={true}
                          value={
                            updateFormData?.v_b_name ?? updateFormData?.v_b_name
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Business Category
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="Business Category"
                          variant="outlined"
                          type="text"
                          name="v_b_category"
                          required={true}
                          value={
                            updateFormData?.v_b_category ??
                            updateFormData?.v_b_category
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Full Address
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="Full Address"
                          variant="outlined"
                          type="text"
                          name="v_full_address"
                          required={true}
                          value={
                            updateFormData?.v_full_address ??
                            updateFormData?.v_full_address
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Email
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="Email"
                          variant="outlined"
                          type="email"
                          name="v_email"
                          required={true}
                          value={
                            updateFormData?.v_email ?? updateFormData?.v_email
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        GSTIN (Optional)
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="GSTIN"
                          variant="outlined"
                          type="text"
                          required={false}
                          name="v_gstin"
                          value={
                            updateFormData?.v_gstin ?? updateFormData?.v_gstin
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        WhatsApp Number
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="WhatsApp Number"
                          variant="outlined"
                          type="tel"
                          name="v_tel"
                          required={true}
                          value={updateFormData?.v_tel ?? updateFormData?.v_tel}
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Web/App Link
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="Web/App Link"
                          variant="outlined"
                          type="text"
                          name="v_link"
                          required={true}
                          value={
                            updateFormData?.v_link ?? updateFormData?.v_link
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Service Charge/ Commission Rate
                      </TableCell>
                      <TableCell align="right">
                        <InputFF
                          label="Service Charge/ Commission Rate"
                          variant="outlined"
                          type="text"
                          name="v_commission_charge"
                          required={true}
                          value={
                            updateFormData?.v_commission_charge ??
                            updateFormData?.v_commission_charge
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Status
                      </TableCell>
                      <TableCell align="right">
                        <SelectDropDownFF
                          label="Select Status"
                          menuItem={selectStatus}
                          name="v_status"
                          required={true}
                          selectedValue={
                            updateFormData?.v_status ?? updateFormData?.v_status
                          }
                          onChange={updateFormStateOnUpdate}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" colSpan={2}>
                        <CheckBoxFF
                          required={true}
                          name="v_terms"
                          label="I hereby accept all the terms & conditions & Services as informed"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{ width: "100%" }}
                >
                  {location?.state?.vendor ? "Update Now" : "Submit Now"}
                </Button>
              </TableContainer>
            </form>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default VendorRegistration;
